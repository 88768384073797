<template>
  <div class="accountset">
    <userheader menucurrent=2 />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex=0 ref="userleft" />

        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">个人信息<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb resetpwd">
                  <el-form :model="form" :rules="rules" label-width="100px" class="elform" ref="elForm">
                    <el-form-item label="头像" prop="userAvatarUrl">
                      <el-upload class="" v-model="form.userAvatarUrl" :headers="myHeaders" list-type="text"
                        :on-remove="handleRemove" :action="uploadUrl" name="file"
                        accept="image/png,image/gif,image/jpg,image/jpeg" :file-list="fileList" :on-success="busSuccess"
                        :on-error="busError" :on-change="busChange" :show-file-list="false">
                        <img v-if="form.userAvatarUrl!=''" style="width:100%;height:100%;" :src="form.userAvatarUrl"
                          class="avatar">
                        <i v-else class="el-icon-plus"></i>
                      </el-upload>
                    </el-form-item>
                    <el-form-item class="formL" label="昵称" prop="userNickName">
                      <el-input v-model="form.userNickName"></el-input>
                    </el-form-item>
                    <el-form-item class="formL " label-width="100px" label="二维码" prop="creditcode">
                       <a href="javascript:;" @click="onuserleft" class=""> <img class="main-nav__qrcode__img"
                  src="../../../assets/img/qrcode-ico.png" width="18" height="18" /></a>
                    </el-form-item>
                    <el-form-item>
                      <el-button @click="onsubmitForm('elForm')">提交</el-button>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogimgVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" top="25vh" width="40%" :before-close="handleClose">
      <el-row>

      </el-row>
    </el-dialog>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token =getCookieByName('access_token');
import {
  usergetcurruserinfo,
  usermodifyavatar,
  usermodifynickname,
  utilsgetuserqrcodebase64,
} from '@/api/user';
import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    const self = this;
    return {
      bigqrcodeurl: '',
      dialogImageUrl: '',
      isshowbusinessend: false,
      dialogVisible: false,
      dialogimgVisible: false,
      firstShow: true,
      show: false,
      height: '600',
      entregtypedata: [],
      entregcategorydata: [],
      entregstatusdata: [],
      fileList: [],
      myHeaders: { Authorization: 'Bearer ' + token },
      uploadUrl: this.$uploadUrlN + '/utils/uploadfile',
      form: {
        userNickName: '',
        userAvatar: '',
        userAvatarUrl: '',
      },
      rules: {
        userNickName: [{ required: true, message: '请输入昵称' }],
        userAvatarUrl: [{ required: true, message: '请上传头像' }],
      },
    };
  },
  methods: {
    onsubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          usermodifynickname({ nickName: this.form.userNickName }).then(
            (res) => {
              this.$message.success('提交成功');
            }
          );
        } else {
          console.log('提交失败!');
          return false;
        }
      });
    },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogimgVisible = true;
    },
    busSuccess(response, file, fileList) {
      var that = this;
      if (response.code == 1) {
        this.form.userAvatar = response.result.fileToken;
        this.form.userAvatarUrl = response.result.url + '/image/256x';
        usermodifyavatar({ filetoken: this.form.userAvatar }).then((res) => {});
      }
    },
    busError(err, file, fileList) {
      fileList = [];
      return this.$message.error('上传失败');
    },
    busChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = [fileList[fileList.length - 1]];
      }
    },
    locatefal() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    onuserleft(){
      this.$refs.userleft.handleqrcode();
    },
    onutilsgetuserqrcodebase64() {
      let params = { desUId:getCookieByName('user_id') };
      utilsgetuserqrcodebase64(params).then((res) => {
        if (res.data.result !== null) {
          this.bigqrcodeurl = 'data:image/jpeg;base64,' + res.data.result;
        }
      });
    },
  },
  created() {
    var that = this;
    usergetcurruserinfo().then((res) => {
      if (res.data.code == 1) {
        this.userinfo = res.data.result;
        console.log(that.userinfo.userAvatar);
        if (that.userinfo.userAvatar) {
          //that.userAvatar = that.userinfo.userAvatar;
          that.form.userAvatarUrl = that.userinfo.userAvatar;
        }
        if (that.userinfo.userNickName) {
          that.form.userNickName = this.userinfo.userNickName;
        }
      }
    });
    this.onutilsgetuserqrcodebase64();
  },
  mounted() {},
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
.main-nav__qrcode__img {
  position: relative;
  top: 2px;
  margin-left: 5px;
}
/**公共css结束**/
/* .accountset >>> form.elform .formaddress .el-input {
  width: 220px;
  margin-right: 10px;
} */
.accountset .info-cnt >>> .el-form-item__content button.showbusinessend {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  margin-left: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.chooseaddress {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}
.accountset >>> form.elform .small .el-form-item__label {
  line-height: 20px;
}
.accountset .account-nav li.cur i.detail {
  background-position: 0 -142px;
  background-image: url('../../../assets/img/account-ico.png');
}
.amapstyle {
  height: 500px;
}
.accountset >>> .el-dialog__wrapper .amap-page-container .address {
  font-size: 14px;
  font-weight: 700;
  position: relative;
  top: -23px;
  width: 80%;
}
</style>
